export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30')
];

export const server_loads = [];

export const dictionary = {
		"/(main)": [6,[3]],
		"/(main)/about": [7,[3]],
		"/auth/forgot": [23],
		"/auth/login": [24],
		"/auth/reset": [25],
		"/auth/signup": [26],
		"/auth/signup/details": [27],
		"/auth/verify": [28],
		"/auth/welcome": [29],
		"/callbacks/email_confirmation": [30],
		"/(main)/careers": [8,[3]],
		"/(main)/changelog": [9,[3]],
		"/(main)/creators/[id]": [10,[3]],
		"/(main)/dashboard": [11,[3]],
		"/(main)/drafts/preview/[hash]": [13,[3]],
		"/(main)/drafts/[id]": [12,[3]],
		"/(editor)/drafts/[id]/update": [4,[2]],
		"/(main)/faqs": [14,[3]],
		"/(main)/forbidden": [15,[3]],
		"/(main)/nfts/[id]": [16,[3]],
		"/(main)/press": [17,[3]],
		"/(main)/privacy-policy": [18,[3]],
		"/(main)/profiles/[id]": [20,[3]],
		"/(main)/profile": [19,[3]],
		"/(editor)/profile/update": [5,[2]],
		"/(main)/search": [21,[3]],
		"/(main)/terms-of-service": [22,[3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';